<template>
  <div class="task_hall_company">
    <el-container>
      <el-header>
        <nav-header :titleType="titleType"></nav-header>
      </el-header>
      <el-container style="height: calc(100% - 60px);">
        <el-aside width="210px" class="aside_container">
          <nav-aside :menuList="menuList"></nav-aside>
        </el-aside>
        <el-main>
          <keep-alive include="CompanyTaskHall">
            <router-view />
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavHeader from '@/components/layout/nav-header/index.vue'
import NavAside from '@/components/layout/nav-aside/index.vue'
export default {
  components: {
    NavHeader,
    NavAside
  },
  data () {
    return {
      menuList: [
        { path: '/company/task-hall', title: '任务大厅', icon: 'iconfont icon-zhuye' },
        { path: '/company/manage', title: '任务管理', icon: 'iconfont icon-renwu' },
        { path: '/company/center', title: '企业中心', icon: 'iconfont icon-qiye' }
      ],
      titleType: 1
    }
  }

}
</script>

<style lang="scss">
.task_hall_company {
  height: 100%;
  .el-main {
    background-color: #F3F3F3;
  }
}
</style>
